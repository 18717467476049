@import "../helpers/helpers.scss";

.required-qty-msg {
  margin: 20px 0;
}
.required-ref-msg {
  margin: 5px 10px;
  text-align:right;
  color:$error;
  @include m-max($sm) {
    text-align:center;
    margin:20px 0;
  }
}
.credit-msg {
  margin: 10px 10px;
  text-align:right;
  color:$error;
  padding-left:400px;
  @include m-max($sm) {
    text-align:center;
    margin:20px 0;
    padding-left:0;
  }
}


.cutoff-msg {
  padding:15px 0;
  color:$error;
}
.required {
  color:$error;
}
.close_modal {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}
.popup-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 0;
  line-height: 21px;
  padding: 20px 10px;

  .header-logo-img {
    margin-bottom: 20px;
  }
}
.page.checkout-page {
  flex-direction: column;

  h2 {
    width: 100%;
    padding-bottom: 20px;
  }

  input {
    -webkit-appearance: radio;
  }
}

.checkout {
  &__form {
    display: flex;
    justify-content: space-around;
    @include m-max($sm) {
      flex-direction: column;
    }

    &,
    .page-title {
      width: 100%;
    }
  }

  &__submit_wrapper {
    flex-direction: column;
    width: 100%;
  }

  &__delivery_title,
  &__details_title {
    text-align: left;
    margin-bottom: 15px;
    font-weight: bold;
  }

  &__first_step,
  &__second_step {
    width: 45%;
    @include m-max($sm) {
      width: 100%;
    }
  }

  &__delivery_item {
    display: flex;
    padding: 20px 0;
    border-top: 1px solid $gray75;

    &:nth-of-type(1) {
      border-top: 0;
    }
  }

  &__delivery-notes-title {
    margin-bottom: 5px;
  }

  &__delivery_field {
    font-weight: 300;
    line-height: 25px;
  }

  &__details_field {
    margin-top: 20px;
  }

  &__delivery_item input[type="radio"] {
    width: 20px;
    height: 20px;
  }

  &__delivery_info {
    padding-left: 10px;
  }

  &__second_step &__details_field {
    margin: 10px 0;
  }

  &__details_textarea {
    width: 100%;
    height: 200px;
    border: none;
    background: $gray75;
    padding: 10px;
    border-radius: 0;
    resize: none;

    &:active,
    &:focus {
      outline: none;
      border: 1px solid $brand;
    }
  }

  &__submit_wrapper {
    margin-top: 20px;

    .grey {
      background: #ccc;
    }
    button {
      width: 10em;
      margin: 0 10px;
    }
  }
}

.payment-methods {
  width:100%;
  @include m-min($sm) {
    padding-right: 18px;
  }
}

.payment-method {
  button.processing {
    cursor: wait;
    background:$brand-hover;
    &:hover {
      background: $brand-hover;
    }
  }
  @include m-min($sm) {
    text-align: right;
    padding-top: 20px;
  }
  @include m-max($sm) {
     button {
      width: 100%;
       margin:0 0 20px 0;
    }
  }
  span {
    @include m-max($sm) {
      display:block;
      text-align: center;
      padding: 10px 0;
    }
  }

  button {
    @include m-min($sm) {
      width: 300px;
    }
  }
}


.react-datepicker {
  border-radius:0;
  border:1px solid $gray75;
  &-wrapper {
    position:relative;
    background:$gray75;
    &:before {
      position: absolute;
      top: 11px;
      right: 10px;
      font-family: $fontAwesome;
      content: '\f073';
      color: $brand-hover;
      z-index: 0;
    }
    input {
      background:none;
      cursor: pointer;
    }
  }
  .react-datepicker__header {
    height: 60px;
    border:0;
  }

  .react-datepicker__navigation {
    top: 0;
    height: 60px;

    &--next {
      right: 0;
    }

    &--previous {
      left: 0;
    }
    &-icon {
      &--next {
        &:before {
          left:-4px;
        }
      }
      &--previous {
        &:before {
          right:-4px;
        }
      }
    }
  }
  .react-datepicker__navigation--next--with-time {
    right:85px;
  }

  .react-datepicker__time-list-item--disabled {
    display:none;
  }
}


.total, .total-amount {
  display:inline-block;
}
.total-amount {
  position: absolute;
  right:10px;
}

.credit_limit {
  width:100%;
  float:left;
  text-align:right;
  span {
    float:right;
    display:inline-block;
    max-width:400px;
  }
}
